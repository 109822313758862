@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?y31xh8');
    src: url('../fonts/icomoon.eot?y31xh8#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?y31xh8') format('truetype'), url('../fonts/icomoon.woff?y31xh8') format('woff'), url('../fonts/icomoon.svg?y31xh8#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-pencil:before {
    content: "\e921";
}

.icon-user-setting:before {
    content: "\e900";
}

.icon-users:before {
    content: "\e901";
}

.icon-laptop:before {
    content: "\e902";
}

.icon-search-alt:before {
    content: "\e903";
}

.icon-settings:before {
    content: "\e904";
}

.icon-growth:before {
    content: "\e905";
}

.icon-arrow-growth:before {
    content: "\e906";
}

.icon-star:before {
    content: "\e907";
}

.icon-chevron-left:before {
    content: "\e908";
}

.icon-bell:before {
    content: "\e909";
}

.icon-up-arrow:before {
    content: "\e90a";
}

.icon-down-arrow:before {
    content: "\e90b";
}

.icon-left-arrow:before {
    content: "\e90c";
}

.icon-home:before {
    content: "\e90d";
}

.icon-right-arrow:before {
    content: "\e90e";
}

.icon-user:before {
    content: "\e90f";
}

.icon-envelope:before {
    content: "\e910";
}

.icon-lock:before {
    content: "\e911";
}

.icon-chevron-down:before {
    content: "\e912";
}

.icon-hide:before {
    content: "\e913";
}

.icon-eye:before {
    content: "\e914";
}

.icon-checkmark:before {
    content: "\e915";
}

.icon-page:before {
    content: "\e916";
}

.icon-car:before {
    content: "\e917";
}

.icon-document:before {
    content: "\e918";
}

.icon-forwaded-call:before {
    content: "\e919";
}

.icon-star-fill:before {
    content: "\e91a";
}

.icon-view:before {
    content: "\e91b";
}

.icon-driver:before {
    content: "\e91c";
}

.icon-more:before {
    content: "\e91d";
}

.icon-refresh:before {
    content: "\e91e";
}

.icon-columns:before {
    content: "\e91f";
}

.icon-filter:before {
    content: "\e920";
}

.icon-calendar:before {
    content: "\e925";
}

.icon-cdollar:before {
    content: "\e922";
    color: #fefaf1;
}

.icon-ecalender:before {
    content: "\e923";
    color: #d2ecf1;
}

.icon-dollar:before {
    content: "\e924";
    color: #fef7fe;
}

.icon-money:before {
    content: "\e926";
}

.icon-support:before {
    content: "\e927";
}

.icon-driver-1:before {
    content: "\e928";
}

.icon-missed:before {
    content: "\e929";
}

.icon-contact:before {
    content: "\e92a";
}

.icon-run:before {
    content: "\e92b";
}

.icon-comment-1:before {
    content: "\e92c";
}