/* You can add global styles to this file, and also import other style files */

$primary-dark: #242424;
$primary-light: #919191;
$secondary: #f26822;

html,
body {
  height: 100%;
  color: $primary-dark;
  font-size: 14px;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: "Poppins", sans-serif !important;
}

.gutter-4 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter-4 [class^="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-5 {
  margin-left: -15px;
  margin-right: -15px;
}

.gutter-5 [class^="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

a {
  color: #28a1fa;
  text-decoration: none;
}

.cursor {
  cursor: pointer !important;
}

.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #28a1fa !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #28a1fa;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: $secondary;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $secondary;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.border-bottom {
  border-bottom: 1px solid #e3e3e3 !important;
}

.animated-background {
  will-change: transform;
  animation: skeleton 1s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1600px 104px;
  position: relative;
  width: 100%;
  height: 14px;

  &.fileds {
    height: 2.5rem;
    padding-bottom: 0.625rem;
    margin: 0.25em 0;
    border-radius: 0.25rem;
  }
}

@keyframes skeleton {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -800px 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 800px 0;
  }
}

.leftsec {
  width: 60%;
  background: #f8f8f8;
  position: sticky;
  bottom: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(./assets/images/login-banner.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 70vh;
  align-self: flex-end;

  .comp-info {
    max-width: 560px;
    margin: 0px auto;
    padding: 20px;
    padding-top: 14vh;

    h1 {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 20px;
      color: $primary-dark;
    }

    p {
      color: #868686;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

.rightsec {
  width: 40%;
  background: #fff;

  .company-logo {
    margin-top: 13vh;
    text-align: center;
    margin-bottom: 14vh;
  }

  .sign-form {
    max-width: 380px;
    margin: 0 auto;
    padding: 20px;

    h2 {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 36px;
      color: $primary-dark;
    }

    &.forgot {
      h2 {
        margin-bottom: 6px;
      }

      p {
        margin-bottom: 30px;
      }
    }
  }

  .sub-title {
    color: #777777;
  }

  ul {
    color: $primary-light;
    font-size: 14px;
  }

  .mat-icon {
    color: $primary-light;
  }

  .icon-eye,
  .icon-hide {
    font-size: 16px;
  }

  .mat-secondary {
    background: $secondary;
    box-shadow: 0px 10px 20px #f4433633;
    padding: 6px;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.25em 0 0.5em 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 3px 3px 0 0;
    padding: 0.5em 0.75em 0 0.75em;
  }
}

.header {
  background: #fff;
  border-bottom: 1px solid #c3c3c3;
  padding: 22px 0px;
  position: sticky;
  top: 0;
  z-index: 11;

  .logo img {
    height: 24px;
  }

  .form-control {
    color: #919191;
    background-color: #f7f7f7;
    border: 0px solid #ced4da;
    height: 30px;
    font-size: 12px;
    max-width: 300px !important;
  }

  .input-group-text {
    background-color: #f7f7f7;
    border: 0px solid #ced4da;
    font-size: 12px;
    color: #919191;
  }

  ul li span {
    color: #8b8b8b;
    font-size: 18px;
  }

  .input-group span {
    font-size: 12px;
  }

  .notification {
    position: relative;

    .active {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #f26822;
      border-radius: 50%;
      top: 0;
      right: 0;
    }
  }
}

.mainmenu {
  background: #fff;
  position: sticky;
  top: 69px;
  z-index: 11;

  .navbar-expand-lg .navbar-nav .nav-item .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .nav-link {
    padding: 18px 0px;
    color: #6d6d6d;
    font-size: 14px;

    span {
      margin-right: 10px;
    }

    &.active {
      border-bottom: 2px solid #f26822;
      background: #fffbf8;
      color: #f26822;
    }
  }
}

.maincontainer {
  background-color: #f5f5f5;

  &.dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bread-crumb {
  padding: 13px 0px;
  background: #f5f5f5;

  .page-title {
    font-size: 20px;
    font-weight: 700;
  }
}

.breadcrumb {
  a {
    color: #9a9a9a;
  }

  .breadcrumb-item.active {
    color: #222222;
  }
}

.vechile-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.vleft {
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 3px;
  padding: 15px;
  width: 550px; // change 500px to 550 to adjust rating in driver profile and also change in vright
  height: fit-content;

  h4 {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 22px;
  }

  .number,
  .status {
    background: #610095;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 14px;
  }

  .status {
    background: #3fbf5d;
  }

  .pin {
    background: #f26822;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 14px;
  }

  .user-img img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  .user {
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 15px;
    position: relative;

    .edit {
      position: absolute;
      right: 0;
      cursor: pointer;
      color: #28a1fa;
    }
  }

  .rating {
    color: #242424;

    span {
      color: #3fbf5d;
    }
  }
}

.vright {
  width: calc(100% - 550px);
  padding-left: 20px;

  .tab-title {
    font-weight: 600;
    position: relative;
    padding: 15px;
    font-size: 16px;

    &.service-call {
      padding: 0;
    }

    .edit {
      position: absolute;
      right: 15px;
      cursor: pointer;
      color: #28a1fa;
      top: 24px;
    }

    span {
      color: #0070f2;
    }
  }

  .tab-title-list {
    font-weight: 600;
    position: absolute;
    padding: 15px;
    font-size: 16px;
    top: 10px;
    left: 0;

    &.service-call {
      padding: 0;
    }

    .edit {
      position: absolute;
      right: 15px;
      cursor: pointer;
      color: #28a1fa;
      top: 15px;
    }

    span {
      color: #0070f2;
    }
  }

  .tab-container {
    padding: 0px 15px;
  }

  .mat-tab-group {
    font-family: "Poppins", sans-serif;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #f26822;
  }

  .mat-tab-label,
  .mat-tab-link {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  .mat-tab-label {
    min-width: 130px;
    height: 42px;
    opacity: 1;
  }

  .mat-tab-label-active {
    background: #fffbf8;
    font-weight: 500;
    color: #f26822;
  }

  .serviceCallDetails {
    background-color: #f5f5f5;
    padding-top: 20px;

    .bgWhite {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #00000014;
      border-radius: 3px;
      position: relative;
    }
  }

  .driver-media {
    .user {
      padding: 20px;

      h6 {
        font-size: 14px;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .vaichel-photos ul {
    margin-right: -7px;
    margin-left: -7px;
    padding: 0px 15px;

    li {
      width: 20%;
      padding-right: 7px;
      padding-left: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 185px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 3px;
      }
    }
  }
}

.box {
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;

  .heading {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px;

    &::after {
      position: absolute;
      top: 36px;
      left: 0;
      content: "";
      border-bottom: 1px solid #e3e3e3;
      width: 100%;
    }
  }

  ul.separet {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1.25rem 0rem;
    list-style: none;
  }

  ul.separet li {
    width: 100%;
    padding: 0.5rem 1.25rem;
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  ul.separet li:nth-child(odd) {
    border-left: none;
  }

  ul.separet li label {
    margin: 0;
    width: 50%;
    max-width: 15rem;
    color: #626262;
  }

  ul.separet li .val {
    width: 50%;
    flex-basis: 0;
    flex-grow: 1;
    color: $primary-dark;
    padding-left: 5px;
    text-align: left;
    text-transform: none;
  }

  .activity {
    cursor: pointer;
    padding: 10px 0px;
    border-bottom: 1px solid #e3e3e3;

    &:hover {
      background-color: #efefef;
    }

    p {
      font-size: 12px;
      color: #0070f2;
      font-weight: 500;
    }

    .d-flex {
      color: #919191;
      font-size: 10px;
      align-items: center;

      span {
        width: 8px;
        height: 8px;
        background-color: #919191;
        border-radius: 50%;
        margin: 0px 5px;
      }
    }
  }
}

.mat-menu-content .material-icons {
  font-size: 18px;
}

.global-filter {
  background-color: #fff;
  padding: 15px;
  position: relative;

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
    font-size: 14px;
  }

  .input-group {
    max-width: 265px;
    height: 40px;
  }

  .form-control {
    background-color: #f8f8f8;
    border: 0px;
    padding-left: 0px;
    color: #666666;
    font-size: 14px;
  }

  .input-group-text {
    background-color: #f8f8f8;
    border: 0px solid #ced4da;
    color: #666666;
  }

  .vdetails {
    box-shadow: none;
    background: transparent;
    height: auto;
  }
}

.mat-tab-group .global-filter {
  position: unset;
}

.mat-form-field {
  font-family: "Poppins", sans-serif;
}

.filter-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 0%;
  background-color: #fff;
  color: #666;
  padding: 0.625rem 0;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  white-space: nowrap;

  &.active {
    padding: 14px 0rem 0.625rem 1.25rem;
    overflow: visible;
    width: 100%;
    white-space: normal;
  }

  & > i {
    margin-right: 1rem;
  }

  .app-filter {
    display: flex;
    align-items: center;
    width: 100%;

    & > i {
      margin-right: 1rem;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 16px 0px 8px 0px;
    font-size: 12px;
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-button.mat-accent,
  .mat-icon-button.mat-accent,
  .mat-stroked-button.mat-accent {
    color: #2a2424;
    background: #f8f8f8;
    margin-right: 20px;
  }

  .chip-close {
    .mat-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      text-align: right;
      margin-top: 5px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .filter-clip {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
      background-color: #f8f8f8;
      color: #2f2f2f;
      border: 1px solid #cccccc;
      font-size: 13px;
      font-weight: 400;
      margin: 0px 16px;
    }

    .add-filter {
      position: relative;
      display: inline-block;

      .row {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }

      .row > [class^="col-"],
      .row > .col {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .filter-list {
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 0rem;
        border-radius: 0.25rem;
        overflow: hidden;
        -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0.25rem 0.625rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0 0.25rem 0.625rem;
        white-space: nowrap;

        &.active {
          width: 17.5rem;
          border: #0062ff solid 0.0625rem;
        }

        .mat-flat-button {
          color: #fff;
          background-color: #f26822;
        }

        .filter-heading {
          padding: 0.25rem 0.25rem 0.25rem 0.5rem;
          color: #fff;
          background-color: #0062ff;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 0.9375rem;
          line-height: 1.25rem;

          button.mat-icon-button {
            width: 1.875rem;
            height: 1.875rem;
            line-height: 1;
          }
        }

        .filter-search {
          padding: 0.25rem;
          background-color: #edf0f3;
          display: flex;
          align-items: center;
          border-bottom: #cfd2d4 solid 0.0625rem;

          .list-searech {
            width: 100%;
          }
        }

        .btn-list {
          padding: 0.5rem;

          button.mat-menu-item {
            display: block;
            width: 100%;
            text-align: left;
            margin-top: 0rem;
            font-size: 0.875rem;
            height: 1.875rem;
            line-height: 1.875rem;
            font-weight: 400;
          }
        }

        .filter-body {
          padding: 0.5rem;
        }

        .filter-action {
          padding: 0.5rem;
          border-top: #cfd2d4 solid 0.0625rem;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.table-box {
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 3px;
  margin-bottom: 15px;
  overflow: auto;
  max-height: 530px;

  .mat-table {
    font-family: "Poppins", sans-serif;

    img {
      max-width: 20px;
    }

    tr.mat-header-row {
      height: 40px;
      background: #f4f4f4;

      th.mat-header-cell {
        font-size: 15px;
        font-weight: 500;
        color: #242424;
        border-right: 1px solid #e3e3e3;
        padding: 0px 20px;
        border-top: 1px solid #e3e3e3;
        white-space: nowrap;
      }
    }

    tr.mat-row {
      height: 40px;

      &:nth-child(even) {
        background-color: #eff3f5;
      }

      td.mat-cell {
        border-right: 1px solid #e3e3e3;
        white-space: nowrap;
        color: #484848;
        font-size: 14px;
        padding: 0px 20px;

        .avil-box {
          padding: 6px 15px;
          display: inline-flex;
          align-items: center;
          font-size: 12px;
          user-select: none;
          border-radius: 3px;
          background-color: #ececec;
          color: #454545;
          text-transform: capitalize;

          &.oncall {
            background-color: #edfff2;
            color: #3fc05e;
          }

          &.offline {
            background-color: #ddeaff;
            color: #0062ff;
          }

          &.logout {
            background-color: #ffe6e6;
            color: #ff0000;
          }
        }

        .stat-box {
          margin-left: 15px;
          display: inline-flex;
          align-items: center;
          position: relative;

          &.active::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #87c43c;
            left: -15px;
          }

          &.inactive::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fd5554;
            left: -15px;
          }

          &.requested::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ff9300;
            left: -15px;
          }

          &.green {
            color: #3fc05e;
          }

          &.blue {
            color: #0062ff;
          }

          &.red {
            color: #ff0000;
          }

          &.yellow {
            color: #ff9300;
          }
        }

        .idCol {
          &.idColor {
            color: #1162fb;
          }
        }
      }
    }
  }

  .pagination-section {
    padding: 15px;
    color: #484848;
    font-size: 12px;

    .form-select {
      font-size: 12px;
      padding: 4px 30px 4px 10px;

      &:focus {
        box-shadow: none;
      }
    }

    .pagination {
      margin-bottom: 0;
    }

    .page-link {
      .sr-only {
        display: none;
      }

      color: #6d6d6d;
      border: 1px solid #e3e3e3;

      &.active {
        background-color: #28a1fa;
        color: #fff;
        border: 1px solid #28a1fa;
      }

      &.next {
        color: #28a1fa;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .page-item.active .page-link {
      color: #fff !important;
    }
  }

  .mat-input-element {
    text-align: center;
    border: 1px solid #e3e3e3;
  }
}

.mat-tab-group .table-box .mat-table {
  tr.mat-header-row {
    position: unset;
    top: unset;

    th.mat-header-cell {
      font-size: 13px;
    }
  }

  tr.mat-row {
    td.mat-cell {
      font-size: 12px;
    }
  }
}

.input_pagination {
  width: 44px !important;
  text-align: center;
  height: 30px !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.vdetails {
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 3px;
  height: 100%;

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    border-top: 0;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 35px;
  }

  .earning1 {
    background: #fdedd3;
    border-radius: 3px;
    padding: 24px 20px;
    font-size: 12px;
    color: #b4996c;

    span {
      font-size: 40px;
    }

    .value {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .earning2 {
    background: #fee3fa;
    border-radius: 3px;
    padding: 24px 20px;
    font-size: 12px;
    color: #cb7fc0;

    span {
      font-size: 40px;
    }

    .value {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .earning3 {
    background: #b4e0ea;
    border-radius: 3px;
    padding: 24px 20px;
    font-size: 12px;
    color: #56818b;

    span {
      font-size: 40px;
    }

    .value {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .nav-pills .nav-link {
    border-radius: 0;
    padding: 8px 30px;
    font-size: 13px;
    border: 1px solid #cccccc;
    color: #626262;
    font-weight: 500;

    &.active {
      color: #fff;
      background-color: #1270f2;
      border: 1px solid #1270f2;
    }
  }
}

// .cdk-overlay-pane {
//     max-width: 800px !important;
// }
.mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.modalEditDriver {
  .btn-close:focus {
    box-shadow: none;
  }

  .modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .row {
      border-bottom: 1px solid #e5e5e5;
    }

    .row:nth-last-child(2),
    .row:last-child {
      border-bottom: 0px;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    display: none;
  }

  .mat-form-field-appearance-fill .mat-form-field-ripple {
    height: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 3px 3px 0px 0px;
    padding: 0.5em 0.5em 0 14px;
    border: 1px solid rgba(102, 102, 102, 0.35);
    background: #fdfdfd;
  }

  .vphoto {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 128px;
    margin-bottom: 16px;
  }

  .vphoto img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .pic-box {
    border: 0.125rem dashed #ddd;
    height: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #28a1fa;
    border-radius: 0.25rem;
    position: relative;
    margin-bottom: 16px;

    .spinner-border {
      border: 2px dashed #000;
      -webkit-animation: 1s linear infinite spinner-border;
      animation: 1s linear infinite spinner-border;
    }

    span {
      z-index: 1;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.25em 0 6px 0;
  }

  .modal-header {
    padding: 15px;

    .modal-title {
      font-size: 16px;
      margin-bottom: 0;
    }

    .btn-close {
      background-color: #e5e5e5;
      border-radius: 50%;
    }
  }

  .modal-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }

  .modal-body {
    padding-bottom: 0px;

    &.document-modal {
      max-height: 500px;
      overflow: auto;
    }
  }

  .modal-body.document-modal::-webkit-scrollbar {
    width: 8px;
  }

  .modal-body.document-modal::-webkit-scrollbar-track {
    background-color: #ebebeb;
  }

  .modal-body.document-modal::-webkit-scrollbar-thumb {
    background-color: #28a1fa;
  }

  .btn-update {
    background: #28a1fa;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #28a1fa;
    color: #fff;
    font-size: 14px;
    border-radius: 0;
    padding: 8px 20px;
  }

  .btn-cancel {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #464646;
    color: #464646;
    font-size: 14px;
    border-radius: 0;
    padding: 8px 20px;
  }

  .close-field {
    .mat-icon {
      width: 20px;
      height: 20px;
      background: #ffd9d8;
      color: #fd0d1b;
      font-size: 20px;
      cursor: pointer;
      border-radius: 3px;
    }
  }

  .add-field {
    .mat-icon {
      width: 20px;
      height: 20px;
      background: #d8efe1;
      color: #7ac998;
      font-size: 20px;
      cursor: pointer;
      border-radius: 3px;
    }
  }
}

.settingPage {
  background: #ffffff;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 3px;

  .table-caption {
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 600;
  }

  .table {
    vertical-align: middle;
  }

  .table > :not(caption) > * > * {
    padding: 0.5rem 1.5rem;
  }

  .cont {
    border: 1px solid #c9c9c9;
    font-size: 12px;
    padding: 8px 12px;
    background-color: #fff;
    width: 100%;
  }

  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #eff3f5;
  }
}

.setting-action {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  padding-bottom: 50px;
}

.dash-status {
  padding-top: 25px;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    li {
      width: 20%;
      padding: 0 10px;

      .box {
        background: #ffffff;
        box-shadow: 0px 3px 6px #00000008;
        border-radius: 5px;
        padding: 10px 20px;

        h4 {
          margin-bottom: 0;
          font-size: 24px;
          font-weight: 600;
        }
      }

      .icon {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        float: right;
      }

      &.online {
        .icon {
          background: transparent
            linear-gradient(90deg, #fdb75b 0%, #fedc78 100%);
        }

        .box {
          border-top: 3px solid #fdb75b;
        }
      }

      &.total {
        .icon {
          background: transparent
            linear-gradient(91deg, #40da92 0%, #4af393 100%);
        }

        .box {
          border-top: 3px solid #40da92;
        }
      }

      &.cancle {
        .icon {
          background: transparent
            linear-gradient(90deg, #fd6b52 0%, #feac6f 100%);
        }

        .box {
          border-top: 3px solid #fd6b52;
        }
      }

      &.ongoing {
        .icon {
          background: transparent
            linear-gradient(90deg, #54bbfd 0%, #52d1fd 100%);
        }

        .box {
          border-top: 3px solid #54bbfd;
        }
      }

      &.complete {
        .icon {
          background: transparent
            linear-gradient(90deg, #4e42de 0%, #7e6eff 100%);
        }

        .box {
          border-top: 3px solid #4e42de;
        }
      }
    }
  }
}

.dash-chart {
  margin-top: 25px;

  .box {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000008;
    border-radius: 5px;
    margin-bottom: 20px;

    .heading {
      color: #626262;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
      border-bottom: 1px solid #e3e3e3;
    }

    .graph-chart {
      padding: 20px;
    }
  }
}

.dashboard-title {
  padding-bottom: 20px;
  font-size: 16px;
}

.cursor-point {
  cursor: pointer;
}

.font-14 {
  font-size: 14px !important;
}

/**********************************************27.4.22**************************************************/

.driver-media {
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;

  .user {
    background: #ffffff;
  }
}

.d-map {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 225px;
  }
}

.payment-mode {
  width: 100%;
}

.sm-block {
  background: #ffffff;
  box-shadow: 0px 2px 16px #00000014;

  .sm-title {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    border-bottom: 1px solid #e3e3e3;
    padding: 10px 1rem;
    margin-bottom: 20px;
  }

  .sm-content {
    width: 100%;
  }
}

.brd-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .btn-right {
    button {
      background: #f26822 !important;
    }
  }
}

#map {
  height: 100%;
}

.no-data-found {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 12%;
}

.assign-service-call {
  float: right;
  margin: -50px 0 25px;
}

.height-200 {
  height: 200px !important;
}

.height-180 {
  height: 180px !important;
}

.activity-sample {
  color: #0070f2;
  font-size: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 10px;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background: #f26822;
}

.ap-button {
  float: right;
  margin-right: 40px;
}

.font-bold {
  font-weight: 600;
}

.greenCol {
  color: #3fc05e;
}

.orangeCol {
  color: #ff9300;
}

.redCol {
  color: #ff0000;
}

.form-width {
  min-width: 300px !important;
  max-width: 600px !important;
  width: 100% !important;
}

.pad-20 {
  margin-right: 20px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.full-width {
  width: 100% !important;
  padding-left: 0px !important;
}

.mar-top-40 {
  margin-top: 40px;
}

.scroll-view {
  overflow: auto;
  max-height: 100vh;
}

.sup_tab {
  color: #f26822;
  font-size: 22px;
}

.mb-50 {
  margin-bottom: -50px;
}

.prof-image-class {
  width: 128px !important;
  left: 80px;
  border: 0.125rem #ddd !important;
}

.prof-img {
  border-radius: 50% !important;
}
